<template>
	<v-row>
		<v-col md="7" sm="6">
			<apexchart
				width="100%"
				height="350px"
				type="area"
				:options="options"
				:series="cUsers"
			></apexchart>
		</v-col>
		<v-col md="5" sm="6">
			<apexchart
				width="100%"
				height="350px"
				type="bar"
				:options="barOptions"
				:series="cRequests"
			></apexchart>
		</v-col>
	</v-row>
</template>

<script>
import { monthsShort, monthsEng } from "@/helpers/variables";

export default {
	name: "AnalyticReportGraphic",

	props: {
		items: {
			required: true,
			validator(e) {
				return typeof e === "object" || e === null;
			},
		},
	},

	data() {
		return {
			barOptions: {
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "20%",
						endingShape: "rounded",
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					categories: monthsShort.rus,
				},
				fill: {
					opacity: 1,
				},
				title: {
					text: "Общее количество заявок",
					margin: 10,
					style: {
						fontSize: "14px",
						fontWeight: "light",
						fontFamily: "Open Sans",
						color: "#263238",
					},
				},
				colors: ["#2eb85c"],
			},

			options: {
				chart: {
					id: "registered-users",
				},
				title: {
					text: "Количество зарегистрированных пользователей",
					margin: 10,
					style: {
						fontSize: "14px",
						fontWeight: "light",
						fontFamily: "Open Sans",
						color: "#263238",
					},
				},
				xaxis: {
					categories: monthsShort.rus,
				},
				markers: {
					size: 5,
				},
				colors: ["#2eb85c"],
				dataLabels: {
					enabled: false,
				},
			},
		};
	},

	computed: {
		cRequests() {
			const data = this.items
				? monthsEng.map(e => this.items.requests_graphic[e])
				: [];
			return [{ name: "Заявок", data }];
		},

		cUsers() {
			const data = this.items
				? monthsEng.map(e => this.items.users_graphic[e])
				: [];
			return [{ name: "Пользователей", data }];
		},
	},

	methods: {
		updateChart() {
			const max = 90;
			const min = 20;
			const newData = this.series[0].data.map(() => {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			});
			// In the same way, update the series option
			this.series = [
				{
					data: newData,
				},
			];
		},
	},
};
</script>

<style></style>
