<template>
	<section class="analytic-report">
		<AnalyticReportHeader :items="analytic" />
		<AnalyticReportGraphic :items="analytic" />
	</section>
</template>

<script>
import AnalyticReportGraphic from "@/components/AnalyticReportGraphic";
import AnalyticReportHeader from "@/components/AnalyticReportHeader";
import { mapGetters } from "vuex";

export default {
	name: "AnalyticReport",

	components: {
		AnalyticReportGraphic,
		AnalyticReportHeader,
	},

	computed: {
		...mapGetters({
			analytic: "ksk/GET_ANALYTIC",
		}),
	},

	created() {
		this.$store.dispatch("ksk/change_analytic_type", "");
	},

	mounted() {
		this.$store.dispatch("ksk/load_analytic");
	},

	beforeDestroy() {
		this.$store.dispatch("ksk/change_analytic_type");
	},
};
</script>

<style lang="scss" scoped>
.charts {
	width: 100%;
}
</style>
