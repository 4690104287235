<template>
	<v-row>
		<v-col v-for="card of cItems" :key="card.title">
			<v-card min-width="200px" elevation="1" flat>
				<v-card-title class="pb-2">
					<v-icon large>{{ card.icon }}</v-icon>
				</v-card-title>
				<v-card-text class="pb-2 text-h5 font-weight-bold">
					{{ card.number || "-" }}
				</v-card-text>
				<v-card-actions
					class="pt-0 px-4 subtitle-2 font-weight-light text-capitalize"
				>
					{{ card.title }}
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "AnalyticReportHeader",

	props: {
		items: {
			required: true,
			validator(e) {
				return typeof e === "object" || e === null;
			},
		},
	},

	data() {
		return {
			cards: [
				{
					title: "пользователей",
					icon: "mdi-account-multiple-outline",
					type: "users_count",
				},
				{
					title: "заявок",
					icon: "mdi-file-document-multiple-outline",
					type: "requests_count",
				},
				{
					title: "квартир",
					icon: "mdi-home-city-outline",
					type: "apartments_count",
				},
				{
					title: "домов",
					icon: "mdi-home-group",
					type: "houses_count",
				},
				{
					title: "сотрудников",
					icon: "mdi-account-multiple-outline",
					type: "employee_count",
				},
			],
		};
	},

	computed: {
		cItems() {
			if (!this.items) return this.cards;
			return this.cards.map(e => ({ ...e, number: this.items[e.type] }));
		},
	},
};
</script>

<style></style>
